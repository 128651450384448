<template>
  <DepotsList />
</template>

<script>
import lang from '@/mixins/language';
import DepotsList from './DepotsList.vue';

export default {
  name: 'DepotsComponent',
  mixins: [lang],
  components: {
    DepotsList,
  },
  data: () => ({
    tab: 0,
  }),
};
</script>

<style scoped>

</style>
