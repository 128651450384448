import { render, staticRenderFns } from "./DepotsComponent.vue?vue&type=template&id=eafd15c8&scoped=true&"
import script from "./DepotsComponent.vue?vue&type=script&lang=js&"
export * from "./DepotsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eafd15c8",
  null
  
)

export default component.exports